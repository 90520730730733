/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { BsFileEarmarkText } from "react-icons/bs"

import Footer from "../components/footer"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PlaceholderImage from "../images/IMG_9723.jpg"

const AcademicPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Academic journal" />
      <div className="hero gap-xl">
        <div className="hero-section-about">
          <Img fluid={data.conference.childImageSharp.fluid} alt="conference" />
          <div className="hero-section-banner">
            <div className="grid-container">
              <h2>Academic journal</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container">
        <div className="grid-x gap-xl">
          <div className="large-6" sx={{ padding: `0rem 3rem 0rem 0rem` }}>
            <h4
              sx={{
                marginBottom: `10px`,
                color: `#faa23a`,
                fontWeight: `600`,
              }}
            >
              Lorem ipsum dolor sit amet
            </h4>
            <h1>Lorem ipsum dolor</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              sit amet massa dolor. Donec semper nulla quis est ornare
              facilisis. Proin lobortis efficitur lectus id ultrices. Vestibulum
              viverra iaculis aliquam. Praesent dictum nulla leo. Nulla
              vulputate rutrum cursus. <br />
              In in dui ante. Sed nec nunc sit amet felis sollicitudin dictum.
              Vivamus consequat eros vitae eros tempor, sed blandit leo laoreet.
              Morbi at sollicitudin nulla, vel blandit urna. Sed finibus massa
              eget finibus malesuada. Integer finibus consequat massa, nec
              pharetra ante vulputate et.
            </p>
            <Link to="/about">
              <button sx={{ marginBottom: `60px` }}>More about tinyML</button>
            </Link>
          </div>
          <div className="large-6">
            <Img fluid={data.tech.childImageSharp.fluid} />
          </div>
        </div>
      </div>

      <div className="grid-container gap-xxxl">
        <h2 className="gap-xl">Journals</h2>
        <div className="grid-x grid-margin-x grid-margin-y">
          {data.allWpAcademicJournal.nodes.map(e => {
            return (
              <div key={e.id} className="large-4 medium-12 small-12 cell">
                <div className="posts__img_holder">
                  <Link to={e.slug}>
                    {e?.academicJournal?.academicImage?.localFile
                      ?.childImageSharp?.fluid ? (
                      <Img
                        fluid={
                          e?.academicJournal?.academicImage?.localFile
                            ?.childImageSharp?.fluid
                        }
                        alt={e.title}
                      />
                    ) : (
                      <img src={PlaceholderImage} sx={{ height: `100%` }} />
                    )}
                  </Link>
                  <Link to={e.slug}>
                    <span className="news-button">Read more</span>
                  </Link>
                </div>
                {/*{e.categories.nodes.map(c => {
                  return (
                    <div key={c.id} className="posts-categories">
                      <span>{c.name}</span>
                    </div>
                  )
                })}
              <br />
                  <span className="news-category">{e.date}</span>*/}
                <Link to={e.slug}>
                  <h3>{e.title}</h3>
                </Link>

                <div
                  dangerouslySetInnerHTML={{
                    __html: e?.academicJournal?.academicExcerpt,
                  }}
                ></div>
              </div>
            )
          })}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default AcademicPage

export const query = graphql`
  query {
    allWpMenu {
      nodes {
        menuItems {
          nodes {
            childItems {
              nodes {
                label
                url
              }
            }
            label
            url
          }
        }
      }
    }
    wpMenu(name: { eq: "Main Menu" }) {
      menuItems {
        nodes {
          label
          childItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
    allWpAcademicJournal {
      nodes {
        id
        slug
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        academicJournal {
          academicContent
          academicExcerpt
          academicImage {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          academicFiles {
            academicFile {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
    conference: file(relativePath: { eq: "IMG_9700.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tech: file(relativePath: { eq: "IMG_9723.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headway: file(relativePath: { eq: "headway-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    summit: file(relativePath: { eq: "summit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emea: file(relativePath: { eq: "emea.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
